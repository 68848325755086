<template>
  <v-container class="px-1 pt-0">
    <!-- Dialog -->
    <v-dialog
      v-model="confirmchargedialog"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title class="text-h5">
          Do you Agree ?
        </v-card-title>
        <v-card-text>
          This is a paid event and will cost {{ challengeInfo.ticketPrice }}pcs of bread.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="green-darken-1"
            class="primary"
            variant="text"
            @click="registerForThisChallenge"
          >
            Yes, I Agree
          </v-btn>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="confirmchargedialog = false"
          >
            Disagree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="confirmGivingDialog"
      persistent
      width="auto"
    >
      <v-card>
        <v-card-title class="text-h5">
          Do you Agree ?
        </v-card-title>
        <v-card-text>
          Please confirm you want to give {{ givingAmount }}pcs of bread.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          
          <v-btn
            color="green-darken-1"
            class="primary"
            variant="text"
            @click="sendSubmitGiving"
          >
            Yes, I Agree
          </v-btn>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="confirmGivingDialog = false"
          >
            Disagree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Attempt Section -->
    <v-row class="mb-0" v-if="calculateTimeLeft(challengeInfo.endTime) != 'Ended'">
      <v-col cols="12" class="pt-0 pb-1" v-if="!registeredForChallenge && !meOwner">
        <div class="text-center pt-1">
          <p>
            Kindly join this Challenge to participate.
          </p>
          <v-btn @click="joinChallenge" color="primary" :disabled="isSubmitting">
            <template v-if="isSubmitting">Submitting...</template>
            <template v-else>
              Join Challenge
            </template>
          </v-btn>
        </div>
      </v-col>
      <v-col cols="12" class="pt-0 pb-1" v-if="registeredForChallenge && !meOwner">
        <v-otp-input
          v-model="attempts"
          class="mt-3 ms-n2 custom-otp-input"
          placeholder="*"
          length="4"
          variant="underlined"
          style="font-size: 40px !important;"
          inputmode="numeric"
        ></v-otp-input>
        <div class="color-boxes">
          <!-- <div class="color-box green"></div>
          <div class="color-box yellow"></div>
          <div class="color-box red"></div>
          <div class="color-box brown"></div>
          -->
          <div class="color-box" :style="{ backgroundColor: colorForIndex(0) }"></div>
          <div class="color-box" :style="{ backgroundColor: colorForIndex(1) }"></div>
          <div class="color-box" :style="{ backgroundColor: colorForIndex(2) }"></div>
          <div class="color-box" :style="{ backgroundColor: colorForIndex(3) }"></div>
        </div>
        <div class="text-center pt-1">
          <v-chip v-if="showAttemptCount" color="" style="color: black;">
            Attempts made: {{ attemptCount }}, &nbsp; Point earned: {{ attemptPoints }}
          </v-chip>
          <v-btn @click="viewRulesSheet" color="primary" plain>
            Read Instructions
          </v-btn>
        </div>
      </v-col>
      <v-col class="text-center pt-1" v-if="registeredForChallenge && !meOwner">
        <v-btn 
          v-if="!isResetAndPlay" 
          @click="makeAttempt" 
          :disabled="!isAttemptButtonActive || isMakingAttempt" 
          color="primary"
          outlined>
          <template v-if="isMakingAttempt">Getting Result...</template>
          <template v-else>
            Make Attempt
          </template>
        </v-btn>
        <v-btn 
          v-if="isResetAndPlay"
          @click="resetAndStart"
          :disabled="!isResetAndPlay"
          color="primary"
          outlined>
          Restart NEW Guess
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-0" v-if="history.length > 0">
      <v-col class="pt-0">
        <!-- Display the history in a table -->
        <table>
          <thead>
            <tr>
              <th>My Guess</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(attempt, index) in history" :key="index">
              <td>{{ attempt.guess }}</td>
              <td>
                <span v-for="(color, colorIndex) in attempt.feedback" :key="colorIndex">
                  <v-icon :style="{ color: getColorStyle(color) }">mdi-circle</v-icon>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </v-col>
    </v-row>
    <!-- Leaderboard Section (Top 5) -->
    <v-row class="mt-0">
      <v-col cols="12" class="px-0 pt-0">
        <v-card>
          <v-card-title class="headline">
            <v-row class="text-center">
              <v-col cols="12" class="px-2 py-2" style="word-break: normal !important;">
                <v-icon>mdi-timer</v-icon> {{ countdownTime }}
                <br/>
                {{ challengeInfo.title }} Leaderboard<br/>
              </v-col>
            </v-row>
          </v-card-title>
          <div class="leaderboard-table" v-if="completeLeaderboard.length > 0">
            <table>
              <thead>
                <tr>
                  <th colspan="2">User</th>
                  <th class="text-right">Points</th>
                  <th class="text-right">Reward</th>
                  <th class="text-right">Last Attempt</th>
                </tr>
              </thead>
              <tbody>
                <tr style="font-size: 80% !important;" v-for="(item, index) in leaderboardTop5" :key="index">
                  <td class="pr-1">{{ index + 1 }}</td>
                  <td class="pl-0" >{{ item.userFullname }}</td>
                  <td class="text-right">{{ item.points }}</td>
                  <td class="text-right">{{ item.reward }}</td>
                  <td class="text-right" style="font-size: 10px !important;">{{ formatLastPlayed(item.playedAt) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="text-center">
            No Winners.
          </div>

          <v-card-actions class="justify-center mt-2 mb-3" v-if="completeLeaderboard.length > 5">
            <v-btn @click="viewCompleteLeaderboard" color="primary" outlined>
              View Complete Leaderboard
            </v-btn>
          </v-card-actions>
        </v-card>
        <div style="margin-bottom: 50px !important;" class="text-center mt-2">
          <v-btn v-if="calculateTimeLeft(challengeInfo.endTime) != 'Ended'" class="green" @click="viewGivingSheet">Increase Reward</v-btn><br/><br/>
          Share with your family and friends<br/>
          <v-btn @click="showShareSheet(challengeInfo)">
            <v-icon>mdi-share-variant</v-icon> 
            Share
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- Complete Leaderboard Bottom Sheet -->
    <v-bottom-sheet v-model="bottomSheet">
      <v-card class="scrollable-card">
        <v-card-title class="headline">{{ challengeInfo.title }} Leaderboard </v-card-title>
        <div class="leaderboard-table">
          <table>
            <thead>
              <tr>
                <th colspan="2">User</th>
                <th class="text-right">Points</th>
                <th class="text-right">Reward</th>
                <th class="text-right">Last Attempt</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in completeLeaderboard" :key="index">
                <td class="pr-0">{{ index + 1 }}</td>
                <td class="pl-0">{{ item.userFullname }}</td>
                <td class="text-right">{{ item.points }}</td>
                <td class="text-right">{{ item.reward }}</td>
                <td class="text-right" style="font-size: 10px !important;">{{ formatLastPlayed(item.playedAt) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </v-card>
    </v-bottom-sheet>

    <v-bottom-sheet v-model="shareSheet" right>
      <v-sheet>
        <v-row class="px-2 pb-2">
          
          <v-col cols="12" class="pb-0">
            Share with your family and loved ones
          </v-col>
          <v-col cols="12" class="px-1">
            <v-btn @click="copyLinkToClipboard(`${baseURL}${challengeInfo.uniqueRef}`)">
              <v-icon>mdi-content-copy</v-icon>
              Copy
            </v-btn>
            &nbsp;
            <v-btn class="mt-1 mr-1" v-for="platform in socialPlatforms" :key="platform.name" @click="shareOnPlatform(platform, challengeInfo)">
              <v-icon>{{ platform.icon }}</v-icon>
              {{ platform.name }}
            </v-btn>
          </v-col>
        </v-row>
      </v-sheet>
    </v-bottom-sheet>

    <!-- Rules Bottom Sheet -->
    <v-bottom-sheet v-model="rulesSheet">
      <v-card>
        <v-card-title class="headline">Challenge Rules</v-card-title>
        <v-card-text>
          <p>We have 4 unique numbers with us, from 0-9</p>
          <p>The numbers don't repeat themselves.</p>
          <p>Make an attempt to tell us the 4 numbers. If you get them arranged correctly before your last attempt, you win a point.</p>
          <p class="mb-1">Feedback for attempts:</p>
          <ol>
            <li>Correct and placed rightly: 🟩 (green) box</li>
            <li>Correct but not placed rightly: 🟨 (yellow) box</li>
            <li>Not correct: 🟥 (red) box</li>
            <li>Black box ⬛ means there is a number that is duplicate</li>

          </ol>
          <p>You need 4 (green) 🟩 boxes to win a point.</p>
          <p>
            I wish you the best, Let the challenge begin !
          </p>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>

    <!-- Give to Increase Reward -->
    <v-bottom-sheet v-model="givingSheet">
      <v-card>
        <v-card-title class="headline"><v-icon>mdi-charity</v-icon>Give to Increase the Reward </v-card-title>
        <v-card-text>
          <p>We understand what it means to give. So we created this opportunity for you to give some bread voluntarily towards this Challenge.</p>
          <v-form ref="depositForm">
            <v-row>
              <v-col cols="12" class="pb-0">
                <v-text-field
                  v-model="givingAmount"
                  label="Amount of Bread"
                  :style="{ fontSize: '28px' }"
                  :rules="givingRules"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  v-model="accountPassword"
                  :rules="passRules"
                  style="fontSize: 16px !important;"
                  label="Your Password"
                  append-icon="mdi-eye"
                  @click:append="togglePasswordVisibility"
                  required></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-btn 
                  :disabled="!$refs.depositForm || !$refs.depositForm.validate() || isSubmittingGiving "
                  @click="submitGiving"
                  class="full-width-btn primary"
                  style="width: 100% !important;">
                  <template v-if="isSubmittingGiving">Giving...</template>
                  <template v-else>
                    Give
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
    <v-snackbar v-model="snackbarLinkCopy" timeout="3000">
      Link copied to clipboard!
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      baseURL: 'https://learnbread.com/events/',
      meOwner: false,
      snackbarLinkCopy: false,
      givingAmount: '',
      history: [],
      showAttemptCount: false,
      givingRules: [
        (v) => !!v || 'This field is required',
        (v) => /^[0-9]+$/.test(v) || 'Only numbers are allowed',
        (v) => (parseFloat(v.replace(/,/g, '')) >= 200) || 'Amount must be at least 200'
      ],
      passRules: [
        (v) => !!v || 'This field is required'
      ],
      showPassword: false,
      accountPassword: '',
      confirmchargedialog: false,
      confirmGivingDialog: false,
      challengeInfo: {
        /* title: 'YWAP Event 1',
        paidType: '',
        ticketPrice: '100' */
      },
      //secretSequence: this.generateRandomSequence(),
      registeredForChallenge: false,
      isPageReady: false,
      endTime: new Date().now,
      countdownTime: null,
      secretSequence: [1, 2, 3, 5],
      attempts: '',
      attemptPoints: 0,
      attemptCount: 4,
      defaultColors: ['#675f5f', '#675f5f', '#675f5f', '#675f5f'],
      feedbackColors: [], // Initialize with default color
      leaderboardTop5: [
        // Add more top 5 leaderboard data as needed
      ],
      leaderboardHeadersTop5: [
        { text: 'Name', value: 'userFullname' },
        { text: 'Points', value: 'points' },/* 
        { text: 'Reward', value: 'reward' }, */
        { text: 'Last Attempt', value: 'playedAt' }
      ],
      completeLeaderboard: [
        // Add more complete leaderboard data as needed
      ],
      leaderboardHeaders: [
        { text: 'Name', value: 'userFullname' },
        { text: 'Points', value: 'points' },/* 
        { text: 'Reward', value: 'reward' }, */
        { text: 'Last Attempt', value: 'playedAt' }
      ],
      shareSheet: false,
      socialPlatforms: [
        { name: 'WhatsApp', icon: 'mdi-whatsapp' },
        { name: 'Email', icon: 'mdi-email' },
        { name: 'Facebook', icon: 'mdi-facebook' },
        { name: 'Twitter', icon: 'mdi-twitter' },
        { name: 'LinkedIn', icon: 'mdi-linkedin' },
        { name: 'Telegram', icon: 'mdi-telegram' }
        // Add more social media platforms as needed
      ],
      bottomSheet: false,
      rulesSheet: false,
      givingSheet: false,
      uniqueRef: '',
      isSubmitting: false,
      isSubmittingGiving: false,
      isMakingAttempt: false,
      isResetAndPlay: false
    }
  },
  computed: {
    /* completeLeaderboardVisible() {
      return this.bottomSheet && this.completeLeaderboard.length > 0
    } */
    isAttemptButtonActive() {
      return this.attempts.length === 4
    }
  },
  created() {
    this.updateCountdown() // Initial call
    setInterval(this.updateCountdown, 1000) // Update every second

    this.feedbackColors = this.defaultColors
    this.$store.commit('loading/setLoading', true, { root: true })
    this.fetchEventDetails()
  },
  methods: {
  
    calculateTimeLeft(endDate) {
      const currentTime = new Date()
      const eventEndDate = new Date(endDate)
      const timeDifference = eventEndDate - currentTime // in milliseconds

      if (timeDifference <= 0) {
        // Event has ended
        //this.remainingTime = 0
        clearInterval(this.countdownInterval)

        return 'Ended'
      }

      const seconds = Math.floor(timeDifference / 1000)
      const minutes = Math.floor(seconds / 60)
      const hours = Math.floor(minutes / 60)
      const days = Math.floor(hours / 24)

      //this.remainingTime = timeDifference

      return `${days > 0 ? days + ' days, ' : ''}${hours % 24} hours, ${minutes % 60} mins left`
    },
    showShareSheet(event) {
      this.selectedEvent = event
      this.shareSheet = true
    },
    
    shareOnPlatform(platform, event) {
      ////console.log('the event is ', event)
      switch (platform.name) {
      case 'WhatsApp':
        this.shareOnWhatsApp(event)
        break
      case 'Email':
        this.shareViaEmail(event)
        break
      case 'Facebook':
        this.shareOnFacebook(event)
        break
      case 'Twitter':
        this.shareOnTwitter(event)
        break
      case 'LinkedIn':
        this.shareOnLinkedIn(event)
        break
      case 'Telegram':
        this.shareOnTelegram(event)
        break
      // Add more cases for other social media platforms
      }
    },
    shareOnFacebook(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${link}`

      this.copyLinkToClipboard(link)
      window.open(facebookShareUrl, '_blank')
    },
    shareOnTwitter(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const message = `Check out ${event.title}: ${link}`
      const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(message)}`
      
      this.copyLinkToClipboard(link)
      
      window.open(twitterShareUrl, '_blank')
    },
    shareOnLinkedIn(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const linkedInShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${link}`
      
      this.copyLinkToClipboard(link)
      window.open(linkedInShareUrl, '_blank')
    },
    shareOnTelegram(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(link)}`
      
      this.copyLinkToClipboard(link)
      window.open(telegramShareUrl, '_blank')
    },
    shareOnWhatsApp(event) {
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const message = `Check out ${event.title}: ${link}`
      const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`
      
      this.copyLinkToClipboard(link)
      window.open(whatsappShareUrl, '_blank')
    },
    shareViaEmail(event) {
      // Implement email sharing logic here
      // Example: You can open the user's default email client with a pre-filled email
      const link = `${this.baseURL}${event.uniqueRef}` // Replace with your actual event link
      const subject = `Check out ${event.title}`
      const body = `Hey, I thought you might be interested in this event: ${link}`
      const emailLink = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`
      
      this.copyLinkToClipboard(link)
      window.open(emailLink, '_blank')
    },
    copyLinkToClipboard(link) {
      ////console.log('link is ', link)
      navigator.clipboard.writeText(link)
        .then(() => {
          this.snackbarLinkCopy = true
        })
        .catch((error) => {
          console.error('Error copying to clipboard:', error)
        })
    },

    getColorStyle(color) {
      // Map the feedback color to a corresponding CSS color
      const colorMap = {
        'red': 'red',
        'yellow': 'yellow',
        'green': 'green',
        'black': 'black'
      }

      return colorMap[color] || 'black' // Default to black if the color is not recognized
    },
    resetAndStart() {
      this.feedbackColors = this.defaultColors

      this.showAttemptCount = false
      this.attempts = ''
      this.attemptPoints = 0
      this.isResetAndPlay = false
      this.history = []
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },
    submitGiving() {
      //this.sendSubmitGiving()
      this.confirmGivingDialog = true
    },
    async sendSubmitGiving() {
      this.confirmGivingDialog = false
      this.isSubmittingGiving = true
      try {
        const credentials = {
          amount: this.validateAndConvertToNumber(this.givingAmount),
          userProfile: this.$store.getters['auth/userInfo'],
          challengeInfo: this.challengeInfo,
          accountPassword: this.accountPassword
        }
        // Dispatch the action defined in your Vuex store module
        const ourdata = await this.$store.dispatch('wallet/submitGiving', credentials)

        ////console.log('our data is here ', ourdata)
        if (ourdata && ourdata.success) {
          //////console.log( our data)
          this.$store.dispatch('snackbar/showSnackbar', {
            message: ourdata.message,
            variant: 'green' // Optional, defaults to 'info'
          })
          this.confirmGivingDialog = false
          this.givingAmount = ''
          this.accountPassword = ''
          this.givingSheet = false

        } else {
          // Handle error response
          this.$store.dispatch('snackbar/showSnackbar', {
            message: ourdata.message,
            variant: 'black' // Optional, defaults to 'info'
          })
          ////console.log('ERROR ! ', ourdata)
          ////console.log('snackbar show')
          this.isSubmittingGiving = false
          
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        //this.submitting = false
        this.isSubmittingGiving = false
      }
    },
    validateAndConvertToNumber(value) {
      // Convert value to string if it's a number
      const stringValue = typeof value === 'number' ? value.toString() : value

      // Remove commas before parsing
      const cleanedValue = stringValue.replace(/,/g, '')

      const parsedAmount = parseFloat(cleanedValue)

      if (isNaN(parsedAmount)) {
        throw new Error('Invalid amount value')
      }

      return parsedAmount
    },
    handleChallengeLeaderboard(ourdata) {
      this.completeLeaderboard = ourdata
      
      if (this.completeLeaderboard && this.completeLeaderboard.length > 0) {
        // Sort the complete leaderboard array based on points in descending order
        this.completeLeaderboard.sort((a, b) => b.points - a.points)

        // Take the top 5 records
        this.leaderboardTop5 = this.completeLeaderboard.slice(0, 5)
      } else {
        // Handle the case where completeLeaderboard is empty or null
        this.leaderboardTop5 = []
      }

      ////console.log('top 5 is ', this.leaderboardTop5)
    },
    async fetchEventDetails() {
      const { uniqueRef } = this.$route.params
      
      if (!uniqueRef) {
        // Redirect to home page if uniqueRef is missing
        this.$store.dispatch('snackbar/showSnackbar', {
          message: 'Wrong URL',
          variant: 'black' // Optional, defaults to 'info'
        })

        return
      }
      // Assuming you have a method to fetch the event details
      try {
        const credentials = {
          uniqueRef: uniqueRef,
          userProfile: this.$store.getters['auth/userInfo']
        }
        // Dispatch the action defined in your Vuex store module
        const ourdata = await this.$store.dispatch('wallet/checkEventDetail', credentials)

        if (ourdata && ourdata.success) {
          ////console.log('our event detail is ', ourdata)

          this.challengeInfo = ourdata.challenge
          
          ////console.log('user info is', this.$store.getters['auth/userInfo'].id)
          ////console.log('challenge info ind is', this.challengeInfo.userId)
          //this.baseURL = `${this.baseURL}${this.challengeInfo.uniqueRef}`

          if (this.$store.getters['auth/userInfo'].id === this.challengeInfo.userId) {
            this.meOwner = true
          }

          this.uniqueRef = uniqueRef

          this.registeredForChallenge = ourdata.isRegistered
          // Update this.endTime based on the event details received
          this.endTime = new Date(ourdata.challenge.endTime)
          
          ////console.log('our leaderboard is ', ourdata.leaderboard)
          ////console.log('our complete leaderboard is ', this.completeLeaderboard)

          this.handleChallengeLeaderboard(ourdata.leaderboard)
          
        } else {
          // Handle error response
          this.$store.dispatch('snackbar/showSnackbar', {
            message: ourdata.message,
            variant: 'black' // Optional, defaults to 'info'
          })
          ////console.log('ERROR ! ', ourdata)
          ////console.log('snackbar show')
          this.isSubmitting = false
          
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        this.$store.commit('loading/setLoading', false, { root: true })
        //this.submitting = false
      }
    },
    async registerForThisChallenge() {
      // Implement your createEvent logic here
      this.isSubmitting = true
      try {
        const credentials = {
          uniqueRef: this.uniqueRef,
          userProfile: this.$store.getters['auth/userInfo'],
          challengeId: this.challengeInfo.id
        }

        ////console.log('our credentials are ', credentials)
        // Dispatch the action defined in your Vuex store module
        const ourdata = await this.$store.dispatch('wallet/registerForAChallenge', credentials)

        ////console.log('our data is here ', ourdata)
        if (ourdata && ourdata.success) {
          //////console.log( our data)
          this.$store.dispatch('snackbar/showSnackbar', {
            message: ourdata.message,
            variant: 'black' // Optional, defaults to 'info'
          })
          this.registeredForChallenge = true
          this.confirmchargedialog = false
        } else {
          // Handle error response
          this.$store.dispatch('snackbar/showSnackbar', {
            message: ourdata.message,
            variant: 'black' // Optional, defaults to 'info'
          })
          ////console.log('ERROR ! ', ourdata.error)
          ////console.log('snackbar show')
          //this.isSubmitting = false
          
        }
      } catch (error) {
        
        ////console.log(error)

      } finally {
        this.isSubmitting = false
      }
    },
    joinChallenge() {
      ////console.log('the challenge infor type is ', this.challengeInfo.paidType)
      if ( this.challengeInfo.paidType === 'free') {
        this.registerForThisChallenge()
      } else {
        this.confirmchargedialog = true
      }
    },
    updateCountdown() {
      const now = new Date()
      const timeDifference = this.endTime - now
      
      if (timeDifference > 0) {
        const seconds = Math.floor((timeDifference / 1000) % 60)
        const minutes = Math.floor((timeDifference / 1000 / 60) % 60)
        const hours = Math.floor((timeDifference / 1000 / 60 / 60) % 24)
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24))

        if (days > 0) {
          this.countdownTime = `${days}d ${hours}h ${minutes}m ${seconds}s`
        } else {
          this.countdownTime = `${hours}h ${minutes}m ${seconds}s`
        }
      } else {
        // Challenge has ended
        //this.countdownTime = null
        // Challenge has ended
        this.countdownTime = 'Challenge Ended'
      }
    },
    generateRandomSequence() {
      const sequence = []
      
      for (let i = 0; i < 4; i++) {
        sequence.push(Math.floor(Math.random() * 10)) // Random number between 0 and 9
      }
      
      return sequence
    },
    async makeAttempt() {
      this.isMakingAttempt = true
      try {
        // Check if the current guess is the same as the last guess in history
        const lastGuess = this.history.length > 0 ? this.history[0].guess : null

        if (!(lastGuess && JSON.stringify(lastGuess) === JSON.stringify(this.attempts))) {
          // Current guess is the same as the last guess
          // You can show an alert or take any other action here
          const credentials = {
            userGuess: this.attempts,
            userProfile: this.$store.getters['auth/userInfo'],
            challengeInfo: this.challengeInfo
          }
          // Dispatch the action defined in your Vuex store module
          const ourdata = await this.$store.dispatch('wallet/makeAttempt', credentials)

          ////console.log('our data is here ', ourdata)
          if (ourdata && ourdata.success) {
            //////console.log( our data)
            this.$store.dispatch('snackbar/showSnackbar', {
              message: ourdata.message,
              variant: 'green' // Optional, defaults to 'info'
            })

            if (ourdata.resetPlay) {
              this.isResetAndPlay = true
            }

            this.attemptCount = ourdata.attemptCount
            this.attemptPoints += ourdata.points
            this.showAttemptCount = true

            this.feedbackColors = ourdata.feedback

            this.handleChallengeLeaderboard(ourdata.leaderboard)
            // Save the current guess and feedback to history
            this.history.unshift({ guess: ourdata.userGuess.slice(), feedback: ourdata.feedback.slice() })

          } else {
            // Handle error response
            this.$store.dispatch('snackbar/showSnackbar', {
              message: ourdata.message,
              variant: 'black' // Optional, defaults to 'info'
            })
            this.attemptCount -= 1
            if (ourdata.resetPlay) {
              this.isResetAndPlay = true
            }
            this.feedbackColors = ourdata.feedback
            // Save the current guess and feedback to history

            this.handleChallengeLeaderboard(ourdata.leaderboard)

            this.history.unshift({ guess: ourdata.userGuess.slice(), feedback: ourdata.feedback.slice() })

            ////console.log('ERROR ! ', ourdata)
            ////console.log('snackbar show')
            this.isMakingAttempt = false

            this.showAttemptCount = true
            this.attemptCount = ourdata.attemptCount
            
          }
        } else {
          this.$store.dispatch('snackbar/showSnackbar', {
            message: 'Your current guess is the same as your last guess',
            variant: 'black' // Optional, defaults to 'info'
          })
        }
        
      } catch (error) {
        
        ////console.log(error)

      } finally {
        //this.submitting = false
        this.isMakingAttempt = false
      }
      /* this.feedbackColors = ['black', 'black', 'black', 'black']

      const userGuess = this.attempts.split('').map(Number) // Convert string to array of numbers

      // Compare the guess with the secret sequence
      const feedback = this.compareGuess(userGuess, this.secretSequence)

      // Update feedback colors
      this.feedbackColors = feedback */

    },
    compareGuess(guess, secret) {
      const feedback = []

      // Check for correct numbers in the correct positions (green)
      for (let i = 0; i < 4; i++) {
        if (guess[i] === secret[i]) {
          feedback.push('green')
        }
      }

      // Check for correct numbers in the wrong positions (yellow)
      for (let i = 0; i < 4; i++) {
        if (guess[i] !== secret[i] && secret.includes(guess[i])) {
          feedback.push('yellow')
        }
      }

      // Check for wrong numbers in any position (red)
      for (let i = 0; i < 4; i++) {
        if (!secret.includes(guess[i])) {
          feedback.push('red')
        }
      }

      return feedback
    },
    colorForIndex(index) {
      return this.feedbackColors[index]
    },
    // ... other methods ...
    viewCompleteLeaderboard() {
      this.bottomSheet = true
    },
    viewGivingSheet() {
      this.givingSheet = true
    },
    viewRulesSheet() {
      this.rulesSheet = true
    },
    formatLastPlayed(dateTime) {
      const date = new Date(dateTime)
      const time = date.toLocaleTimeString()
      const formattedDate = date.toLocaleDateString()

      return `${time} - ${formattedDate}`
    }
  }
}
</script>

<style scoped>
/* Add custom styles if needed */
.leaderboard-table {
  overflow-x: auto; /* Enable horizontal scrolling */
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

th, td {
  border-top: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.scrollable-card {
  max-height: 70vh; /* Set a maximum height for the card */
  overflow-y: auto; /* Enable vertical scrolling */
}

/* .color-boxes {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.color-box {
  width: 50px;
  height: 50px;
  border-radius: 20px;
} */

/* Add custom styles if needed */
.color-boxes {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.color-box {
  width: 50px;
  height: 50px;
  border-radius: 20px;
  transition: background-color 1.9s ease; /* Add transition property for smooth color changes */
}

.green {
  background-color: green;
}

.yellow {
  background-color: yellow;
}

.red {
  background-color: red;
}

.brown {
  background-color: brown;
}

::v-deep .v-input {
  font-size: 41px !important;
}
</style>
